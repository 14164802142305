export function useApp() {
  const url = useRequestURL();

  const isCustomerApp = computed(() => url.host.includes('jouw.'));

  const logo = computed(() => `/images/${isCustomerApp.value ? 'logo_jouw.svg' : 'logo.svg'}`);

  const appName = computed(() => isCustomerApp.value ? 'Jouw GoRoadtrip' : 'GoRoadtrip');
  
  return {
    isCustomerApp,
    logo,
    appName,
  };
}
